  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-32-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert sécheresse Avignon</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>L’expertise sécheresse à Avignon</h1>

<p>Si vous constatez des fissures sur votre habitation suite à un épisode de sécheresse, vous avez certainement des questions concernant la gravité de ces désordres et sur le type de réparation à entreprendre.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert sécheresse Avignon" />
</div>


<p>Mais surtout la question primordiale : votre assureur va-t’il vous indemniser ?</p>

<p>Dès la parution d’un Arrêté Catnat sécheresse au journal officiel et approuvé à Avignon ou sur votre commune avoisinante, vous avez alors 10 jours pour déclarer votre sinistre à votre assurance.</p>

<p>L’assurance va alors dépêcher un expert d’assurance qui va déterminer si la sécheresse est bien la source d’apparition de vos désordres.</p>

<p>En cas de refus de garantie, pas de panique, vous avez la possibilité d’être accompagné par un expert bâtiment spécialiste des pathologies liées à la sécheresse.</p>

<h2>L’expert d’assuré sécheresse défend vos intérêts</h2>

<p>L’expert d’assuré sécheresse est un professionnel indépendant de la construction. Il intervient à votre demande et non à celle de l’assurance.</p>

<p>Il défend vos intérêts.</p>

<p>Il intervient, sous mandat, afin d’effectuer une analyse technique de vos désordres générés par l’épisode de sécheresse.</p>

<p>Il détermine ainsi si la sécheresse visée par l’Arrêté Cat Nat, est la cause déterminante d’apparition des fissures sur votre ouvrage ou non.</p>

<h2>L’expertise sécheresse du cabinet RV Expertises</h2>

<p>Le cabinet RV Expertises propose un accompagnement personnalisé de nos clients dans la gestion de leur dossier sécheresse.</p>

<p>Nous déterminons la cause et l’origine des fissures et vous orientons sur les travaux les plus adaptés.</p>

<p>En cas de recours amiable, nous vous accompagnons en contre-expertise face à votre assurance jusqu’à l’obtention d’une juste indemnisation de votre sinistre.</p>

<p>Faites-vous accompagner par un expert spécialisé sécheresse, n’hésitez-pas à contacter le cabinet RV Expertises.</p>

<p></p>
<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Demandez un devis</a>
    </div>
</div>


                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-secheresse-avignon/' className='active'>Expert sécheresse Avignon</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details